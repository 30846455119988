import { PublicClientApplication, LogLevel } from "@azure/msal-browser";

const msalConfig = {
    auth: {
        clientId: "ddb1497e-b8c6-4b40-b8df-fcfda65f8a85",
        authority: "https://login.microsoftonline.com/448b5e00-0015-465f-8b22-616ef5273959",
        redirectUri: process.env.NODE_ENV === 'development' ? "http://localhost:3000" : "https://alice-train.knowbots.org"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) { return; }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
            piiLoggingEnabled: false,
            logLevel: LogLevel.Verbose,
        }
    }
};

const loginRequest = {
    scopes: ["User.Read"],
};

const msalInstance = new PublicClientApplication(msalConfig);

export { msalInstance, loginRequest };
