import { BlobServiceClient } from '@azure/storage-blob';
import { saveAs } from 'file-saver';

const blobServiceUrl = process.env.REACT_APP_BLOB_SAS_URL; // Your Blob service URL
const sasToken = process.env.REACT_APP_SAS_TOKEN; // Your SAS token

const downloadMemories = async () => {
    try {
        console.log("Starting the download process...");

        const fullUrl = `${blobServiceUrl}?${sasToken}`;
        console.log(`Blob Service URL: ${fullUrl}`);
        const blobServiceClient = new BlobServiceClient(fullUrl);
        const containerClient = blobServiceClient.getContainerClient('memories-nonvector');

        console.log("Accessing container: memories");

        const files = [];
        console.log("Listing blobs in the 'memories' container...");

        // List blobs in the 'memories' container and read their contents
        for await (const blob of containerClient.listBlobsFlat()) {
            console.log(`Blob found: ${blob.name}`);
            const blockBlobClient = containerClient.getBlockBlobClient(blob.name);
            const downloadBlockBlobResponse = await blockBlobClient.download();

            // Convert response to Blob and then to string
            const blobContent = await downloadBlockBlobResponse.blobBody;
            const downloadedContent = await blobToString(blobContent);

            if (downloadedContent) {
                files.push({ name: blob.name, content: downloadedContent });
                console.log(`Blob ${blob.name} read successfully.`);
            } else {
                console.error(`Failed to read blob ${blob.name}: no blob content.`);
            }
        }

        if (files.length === 0) {
            console.log("No files found in the 'memories' container.");
            alert("No files found in the 'memories' container.");
            return;
        }

        console.log("Blobs read successfully. Generating conversations...");

        const conversations = files.map(file => {
            const messages = file.content.split('\n').map(line => {
                if (line.startsWith("You:")) {
                    return { role: "user", content: line.slice(4).trim() };
                } else if (line.startsWith("ALICE:")) {
                    return { role: "assistant", content: line.slice(6).trim() };
                }
                return null;
            }).filter(Boolean);

            return {
                filename: file.name,
                messages
            };
        });

        console.log("Conversations generated successfully. Creating JSON content...");

        const jsonContent = JSON.stringify(conversations, null, 2);

        const blob = new Blob([jsonContent], { type: "application/json" });
        saveAs(blob, "alice_memories.json");

        console.log("Download initiated successfully.");
    } catch (error) {
        console.error('Error generating JSON file:', error);
        throw error;
    }
};

// Helper function to convert a Blob to a string
const blobToString = async (blob) => {
    return await blob.text();
};

const DownloadMemories = ({ onDownload }) => {
    return (
        <button onClick={onDownload}>
            Download Memories
        </button>
    );
};

export { DownloadMemories, downloadMemories };
